import plugin from 'vue-yandex-maps'
import { defineNuxtPlugin } from 'nuxt/app'

const settings = {
	//apiKey: process.env.NUXT_PUBLIC_YANDEX_API_KEY,
	apiKey: '66caa346-c3a2-46a5-ac50-7ebca9e05cb5',
	lang: 'ru_RU',
	version: '2.1'
}

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(plugin, settings)
})