import revive_payload_client_4sVQNw7RlN from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/app/www/totdom.com/releases/20240930165208/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import plugin_hwlJ5PmHMh from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import api_GFfDXud5Cr from "/var/www/app/www/totdom.com/releases/20240930165208/plugins/api.ts";
import device_PxhEqcDcOg from "/var/www/app/www/totdom.com/releases/20240930165208/plugins/device.ts";
import directive_I6YZ1QHKAO from "/var/www/app/www/totdom.com/releases/20240930165208/plugins/directive.ts";
import sentry_client_shVUlIjFLk from "/var/www/app/www/totdom.com/releases/20240930165208/plugins/sentry.client.ts";
import vue_scroll_spy_JP8fUaKBu6 from "/var/www/app/www/totdom.com/releases/20240930165208/plugins/vue-scroll-spy.ts";
import yandex_map_client_9cl7nhyqlk from "/var/www/app/www/totdom.com/releases/20240930165208/plugins/yandex-map.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  plugin_hwlJ5PmHMh,
  plugin_AOuQ1DYzjk,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  api_GFfDXud5Cr,
  device_PxhEqcDcOg,
  directive_I6YZ1QHKAO,
  sentry_client_shVUlIjFLk,
  vue_scroll_spy_JP8fUaKBu6,
  yandex_map_client_9cl7nhyqlk
]