import { default as _91slug_93p0kHpdC8oaMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/[slug].vue?macro=true";
import { default as indexj9hPnyDtbRMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/advertising/index.vue?macro=true";
import { default as updatepn22eYqpz2Meta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/[id]/update.vue?macro=true";
import { default as create_45demoNu34XyQTkvMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/create-demo.vue?macro=true";
import { default as createTXDgv1011mMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/create.vue?macro=true";
import { default as indexJ4019gNJOXMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/index.vue?macro=true";
import { default as updateao2aBq9Ik2Meta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/companies/[id]/update.vue?macro=true";
import { default as createxTQGM1kD2wMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/companies/create.vue?macro=true";
import { default as indexSApcoIQCwtMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/companies/index.vue?macro=true";
import { default as index7gBJgsJUQrMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/documents/index.vue?macro=true";
import { default as indexbcVK5A4nBOMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/index.vue?macro=true";
import { default as signinCNuNxbx3iXMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/signin.vue?macro=true";
import { default as indexoNbrR9t6EIMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/index.vue?macro=true";
import { default as _91_91path_93_93hG2gIEgW0yMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/kupit-[type]/[[path]].vue?macro=true";
import { default as _91slug_93JgAqHizTRxMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/kupit/[slug].vue?macro=true";
import { default as _91_91path_93_93adl2Whvcz6Meta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/poselki/[[path]].vue?macro=true";
import { default as _91slug_93OEqTydGaySMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/poselok/[slug].vue?macro=true";
import { default as testTdRj2pm8KJMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/test.vue?macro=true";
import { default as _91slug_93uqNKiNjaCDMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/zastroyschik/[slug].vue?macro=true";
import { default as indexSMbeWpetXsMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/zastroyschiki/index.vue?macro=true";
import { default as _91slug_93tLCrHq4arKMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/zhiloy-kompleks/[slug].vue?macro=true";
import { default as _91_91path_93_93grCfUuWlTRMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/pages/zhilyye-kompleksy/[[path]].vue?macro=true";
import { default as component_45stubmU7eoGQtuOMeta } from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmU7eoGQtuO } from "/var/www/app/www/totdom.com/releases/20240930165208/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "advertising",
    path: "/advertising",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/advertising/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-ads-id-update",
    path: "/cabinet/ads/:id()/update",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-ads-create-demo",
    path: "/cabinet/ads/create-demo",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/create-demo.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-ads-create",
    path: "/cabinet/ads/create",
    meta: createTXDgv1011mMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/create.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-ads",
    path: "/cabinet/ads",
    meta: indexJ4019gNJOXMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/ads/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-companies-id-update",
    path: "/cabinet/companies/:id()/update",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/companies/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-companies-create",
    path: "/cabinet/companies/create",
    meta: createxTQGM1kD2wMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/companies/create.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-companies",
    path: "/cabinet/companies",
    meta: indexSApcoIQCwtMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-documents",
    path: "/cabinet/documents",
    meta: index7gBJgsJUQrMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet",
    path: "/cabinet",
    meta: indexbcVK5A4nBOMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-signin",
    path: "/cabinet/signin",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/cabinet/signin.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexoNbrR9t6EIMeta || {},
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kupit-type-path",
    path: "/kupit-:type()/:path?",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/kupit-[type]/[[path]].vue").then(m => m.default || m)
  },
  {
    name: "kupit-slug",
    path: "/kupit/:slug()",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/kupit/[slug].vue").then(m => m.default || m)
  },
  {
    name: "poselki-path",
    path: "/poselki/:path?",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/poselki/[[path]].vue").then(m => m.default || m)
  },
  {
    name: "poselok-slug",
    path: "/poselok/:slug()",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/poselok/[slug].vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "zastroyschik-slug",
    path: "/zastroyschik/:slug()",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/zastroyschik/[slug].vue").then(m => m.default || m)
  },
  {
    name: "zastroyschiki",
    path: "/zastroyschiki",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/zastroyschiki/index.vue").then(m => m.default || m)
  },
  {
    name: "zhiloy-kompleks-slug",
    path: "/zhiloy-kompleks/:slug()",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/zhiloy-kompleks/[slug].vue").then(m => m.default || m)
  },
  {
    name: "zhilyye-kompleksy-path",
    path: "/zhilyye-kompleksy/:path?",
    component: () => import("/var/www/app/www/totdom.com/releases/20240930165208/pages/zhilyye-kompleksy/[[path]].vue").then(m => m.default || m)
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1008-Kottedjniy-poselok-Riga-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1009-Kottedjniy-poselok-Ivolgi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1030-Kottedjniy-poselok-Ushakovskie-Dachi-1-i-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1042-Dachniy-poselok-Novorigskie-Sadi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1044-Dachniy-poselok-Ruzskie-Sadi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1046-Poselok-taunhausov-Saburovo-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1051-Poselok-taunhausov-Villa-Nova.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1070-Kottedjniy-poselok-Boyarovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1078-Kottedjniy-poselok-Renessans-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1088-Kottedjniy-poselok-Eskvayr-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1100-Dachniy-poselok-Amelfino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1153-Kottedjniy-poselok-Leonovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1154-Kottedjniy-poselok-Zorino-Sport-Villadg.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1166-Dachniy-poselok-Novinki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1172-Kottedjniy-poselok-Martovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1173-Kottedjniy-poselok-Novorigskie-Ozera.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/11-Kottedjniy-poselok-Davidovskoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1208-Dachniy-poselok-Yahroma-River.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1220-Dachniy-poselok-Bligniy-Bereg.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1221-Dachniy-poselok-Grand-Ruza-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1222-Dachniy-poselok-Ilinskie-Prudi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1233-Dachniy-poselok-Chistie-Prudi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1246-Dachniy-poselok-Vishneviy-Sad.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1247-Dachniy-poselok-Ozero-Gemchugin.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1249-Dachniy-poselok-Nadegdino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1270-Kottedjniy-poselok-Lesnaya-Polyana.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1292-Kottedjniy-poselok-Glebovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1325-Kottedjniy-poselok-Rodniki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/132-Kottedjniy-poselok-Usadba-Velednikovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1354-Kottedjniy-poselok-Arhangelskiy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1397-Poselok-taunhausov-Futuro-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1402-Kottedjniy-poselok-Korenki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1403-Kottedjniy-poselok-Vyazemskie-Sadi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1458-Kottedjniy-poselok-Chistie-Prudi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1485-Kottedjniy-poselok-Dva-Ruchya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1499-Zhiloy-kompleks-Krasnogorsk-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1511-Kottedjniy-poselok-Eko-Ozero-Begin-Lug-Eko-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1514-Kottedjniy-poselok-Golfstrim.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/152-Kottedjniy-poselok-Bunkovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1533-Poselok-taunhausov-Nikolskoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/153-Kottedjniy-poselok-Grin-Hill.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/155-Kottedjniy-poselok-Knyage-Ozero.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/157-Kottedjniy-poselok-Kotovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1584-Dachniy-poselok-Solnechnaya-Dolina.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/158-Kottedjniy-poselok-Krona.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1602-Kottedjniy-poselok-Novorigskaya-Usadba-1-Usadbi-Chesnokovo-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/160-Kottedjniy-poselok-Onegino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/162-Kottedjniy-poselok-Svetlogore.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1642-Poselok-taunhausov-Aleksandrovskiy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/164-Kottedjniy-poselok-Stariy-Svet.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/165-Kottedjniy-poselok-Finskaya-Derevnya-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1660-Kottedjniy-poselok-Gorki-6.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1677-Kottedjniy-poselok-Pokrovskie-Vorota.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1690-Zhiloy-kompleks-Novosnegiryovskiy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1705-Dachniy-poselok-Shishaiha.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1752-Kottedjniy-poselok-Lesnoy-Ostrov.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1756-Kottedjniy-poselok-Bortniki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1762-Kottedjniy-poselok-Semeyniy-Novoe-Zadorino-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1775-Dachniy-poselok-Pokrovskoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1858-Dachniy-poselok-Lesnaya-Polyana.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1886-Zhiloy-kompleks-Solnechnaya-Dolina.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1915-Kottedjniy-poselok-Provans.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1916-Kottedjniy-poselok-Berezovka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1917-Kottedjniy-poselok-Ribatskaya-Derevnya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1920-Kottedjniy-poselok-Zapovednoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1931-Kottedjniy-poselok-Alpiyskie-Luga.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1932-Kottedjniy-poselok-Rigskie-Dachi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/1962-Zhiloy-kompleks-Olimp.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2004-Poselok-taunhausov-Nikolskiy-Park-Guravli-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2028-Kottedjniy-poselok-Neskuchniy-Sad.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2033-Kottedjniy-poselok-Givopisniy-Bereg.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2034-Kottedjniy-poselok-Ruzza.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2050-Dachniy-poselok-Chistie-Prudi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2059-Kottedjniy-poselok-Glinki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2065-Dachniy-poselok-2x2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2067-Kottedjniy-poselok-Kurtnikovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2081-Kottedjniy-poselok-Shishkin.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2108-Kottedjniy-poselok-Park-Fonte.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2110-Kottedjniy-poselok-Ten.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2118-Kottedjniy-poselok-Laguna-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2121-Dachniy-poselok-Solnechnaya-Dolina.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2133-Dachniy-poselok-Trubino-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2143-Kottedjniy-poselok-Zagorodniy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2160-Dachniy-poselok-Ozerniy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2167-Kottedjniy-poselok-Sovino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2187-Kottedjniy-poselok-Lesnaya-Polyana.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2211-Kottedjniy-poselok-Morskoy-Klub.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2212-Kottedjniy-poselok-Tiliktinskie-Dachi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2213-Kottedjniy-poselok-Akatovo-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2214-Kottedjniy-poselok-Istlandiya-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2221-Kottedjniy-poselok-Arkadiya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2224-Kottedjniy-poselok-Novorigskie-Dachi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2225-Kottedjniy-poselok-Bereg-Vyazemskih-Yugniy-kvartal.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2226-Kottedjniy-poselok-Bereg-Vyazemskih-Severniy-kvartal.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/224-Kottedjniy-poselok-Zalese.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2253-Kottedjniy-poselok-Roshcha.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2276-Kottedjniy-poselok-ArtEko-ArtEco-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2295-Kottedjniy-poselok-Drugie-Dachi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/22-Kottedjniy-poselok-Istra-Kantri-Klab.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2301-Kottedjniy-poselok-Shato-Soveren.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2302-Kottedjniy-poselok-Timoshkino-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2303-Kottedjniy-poselok-Rishele.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2307-Kottedjniy-poselok-Polyani.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2334-Kottedjniy-poselok-Rigskaya-Roza.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2335-Kottedjniy-poselok-Idalgo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2336-Kottedjniy-poselok-Zagore.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2341-Kottedjniy-poselok-Radost.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2342-Kottedjniy-poselok-Riga-Riga.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2346-Dachniy-poselok-Fedchino-Layk.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2347-Dachniy-poselok-Dubovaya-Roshcha.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2351-Kottedjniy-poselok-Duhanino-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2354-Kottedjniy-poselok-Istra-Layf.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2356-Kottedjniy-poselok-Vazuza-Kantri-Klab.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2361-Kottedjniy-poselok-Podmoskovnie-Prostori.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2376-Kottedjniy-poselok-Lesogore.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2378-Kottedjniy-poselok-Russkie-Sezoni-Rechnoy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2379-Kottedjniy-poselok-Russkie-Sezoni-Lesnoy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2397-Dachniy-poselok-Solnechnaya-Dolina-1.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2401-Dachniy-poselok-Lesnaya-Polyana-1.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2422-Kottedjniy-poselok-Krutoy-Bereg.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2423-Dachniy-poselok-Vishneviy-Sad-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2424-Kottedjniy-poselok-Karintiya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2468-Kottedjniy-poselok-Schaste.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2471-Kottedjniy-poselok-Skrepyashchevo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2472-Kottedjniy-poselok-Barinovo-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2477-Kottedjniy-poselok-Angliyskiy-kvartal-v-Anosino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2486-Kottedjniy-poselok-Neskuchnoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2488-Kottedjniy-poselok-Petrovskiy-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2490-Kottedjniy-poselok-Britanika.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2503-Kottedjniy-poselok-Tsarskie-Berega.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2527-Kottedjniy-poselok-Penati.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2532-Dachniy-poselok-Podsolnuh-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2533-Kottedjniy-poselok-Mastergrad.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2535-Kottedjniy-poselok-Sitniki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2538-Kottedjniy-poselok-Olshani.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2539-Kottedjniy-poselok-Matrenino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/254-Kottedjniy-poselok-Antonovka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2563-Kottedjniy-poselok-Kubasovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2564-Kottedjniy-poselok-Nikulino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2566-Kottedjniy-poselok-Sputnik.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2567-Kottedjniy-poselok-Vinogradovka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2570-Kottedjniy-poselok-Sapronovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2571-Kottedjniy-poselok-Kamenniy-Ruchey.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2573-Kottedjniy-poselok-Triumf.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2574-Kottedjniy-poselok-Lesnoe-Ozero.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2577-Kottedjniy-poselok-Pochinki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2584-Kottedjniy-poselok-Rogdestveno-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2600-Kottedjniy-poselok-Berezovka-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2601-Kottedjniy-poselok-Rogdestveno-3.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2602-Kottedjniy-poselok-Novorigskiy-Plyag-Cherlenkovo-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2603-Kottedjniy-poselok-Rogdestveno.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2604-Kottedjniy-poselok-Tsarskie-Berega-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2615-Kottedjniy-poselok-Pavlovskie-Sosni.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2636-Kottedjniy-poselok-Dolina-Usadeb.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2637-Kottedjniy-poselok-Zorino-Forest-Klab.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2638-Kottedjniy-poselok-Lisichkin-Bor.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2640-Kottedjniy-poselok-Shelestovo-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2641-Kottedjniy-poselok-Dolina-Ozer.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2644-Kottedjniy-poselok-Ruzskiy-Mis.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2650-Kottedjniy-poselok-Koskovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2651-Kottedjniy-poselok-Pereleski.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2665-Kottedjniy-poselok-Riversayd.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2675-Kottedjniy-poselok-Elgozino-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2678-Kottedjniy-poselok-Istok.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2681-Kottedjniy-poselok-Nazarevo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/270-Kottedjniy-poselok-Priozere.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2714-Dachniy-poselok-Niva.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/2732-Kottedjniy-poselok-Zolotie-Peski.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/287-Kottedjniy-poselok-Chistie-Prudi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/304-Kottedjniy-poselok-Solnechnaya-Dolina.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/310-Kottedjniy-poselok-Solnechnaya-Polyana.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/333-Kottedjniy-poselok-Nikolskaya-Sloboda.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/357-Kottedjniy-poselok-Kristal-Istra.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/35-Kottedjniy-poselok-Noviy-Svet.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/363-Kottedjniy-poselok-Fridom.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/373-Kottedjniy-poselok-Malenkaya-Italiya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/376-Kottedjniy-poselok-Montevil.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/389-Kottedjniy-poselok-Kleymenovo-1.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/392-Kottedjniy-poselok-Petrovskiy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/39-Kottedjniy-poselok-Novahovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/401-Kottedjniy-poselok-Pavlovi-Ozyora.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/420-Kottedjniy-poselok-Istrinskaya-Sloboda.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/422-Kottedjniy-poselok-Russkaya-Derevnya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/423-Kottedjniy-poselok-Belie-Rosi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/42-Poselok-taunhausov-Novoarhangelskoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/430-Kottedjniy-poselok-Belaya-Gora-1.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/432-Poselok-taunhausov-Pokrovskiy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/435-Kottedjniy-poselok-Pokrovskoe-Rubtsovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/442-Kottedjniy-poselok-Begin-Lug.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/443-Kottedjniy-poselok-Lesnaya-Rapsodiya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/445-Kottedjniy-poselok-Istrinskie-Holmi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/446-Kottedjniy-poselok-Rodnie-Prostori.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/447-Kottedjniy-poselok-Viktorian-Park-Akatovskie-Dachi-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/448-Kottedjniy-poselok-Lama-Villadg-Lama-Lama-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/449-Kottedjniy-poselok-Derevnya-Ozerna.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/469-Kottedjniy-poselok-Rogdestveno.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/46-Kottedjniy-poselok-Opushkino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/48-Kottedjniy-poselok-Pavlovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/500-Kottedjniy-poselok-Andreykovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/523-Kottedjniy-poselok-Barskoe.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/526-Kottedjniy-poselok-Chistie-Prudi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/537-Kottedjniy-poselok-La-Mansh.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/542-Kottedjniy-poselok-Rechnoy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/609-Kottedjniy-poselok-Novorigskie-Klyuchi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/618-Kottedjniy-poselok-Agalarov-Esteyt.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/619-Kottedjniy-poselok-Orang-Klab-Veretenki-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/61-Kottedjniy-poselok-Tretya-Ohota.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/629-Kottedjniy-poselok-Voskresenki.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/634-Kottedjniy-poselok-Zarechniy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/661-Kottedjniy-poselok-Po-rigskaya-Mechta.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/662-Kottedjniy-poselok-Istrinskie-Usadbi-Istrinskie-Pomeshchiki-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/672-Zhiloy-kompleks-Rezidentsiya-Rublevo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/673-Kottedjniy-poselok-Istlandiya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/679-Kottedjniy-poselok-Rogdestveno.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/690-Poselok-taunhausov-Novorigskiy.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/706-Kottedjniy-poselok-Lvovskie-Ozera.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/70-Kottedjniy-poselok-Karpovi-Vari.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/727-Kottedjniy-poselok-Medison-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/735-Kottedjniy-poselok-Dachi-Honka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/742-Kottedjniy-poselok-Ruzskie-Berega.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/743-Kottedjniy-poselok-Ruza-Femili-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/744-Kottedjniy-poselok-Novorigskiy-Oazis.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/75-Kottedjniy-poselok-Millenium-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/76-Kottedjniy-poselok-Grinfild.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/787-Kottedjniy-poselok-Les-i-Reka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/79-Kottedjniy-poselok-Shervud.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/80-Kottedjniy-poselok-Lesnaya-Usadba.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/812-Kottedjniy-poselok-Gorki-9-18-Salvador-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/814-Kottedjniy-poselok-Alyoshkino-3.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/837-Kottedjniy-poselok-Zareche.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/838-Kottedjniy-poselok-Velednikovo-Delyuks.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/842-Kottedjniy-poselok-Radosti-Park.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/872-Kottedjniy-poselok-Ruzskie-Dachi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/8-Kottedjniy-poselok-Rezidentsii-Benilyuks.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/905-Kottedjniy-poselok-Markovo-Kursakovo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/920-Dachniy-poselok-Holmets.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/921-Dachniy-poselok-Dorino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/922-Dachniy-poselok-Iskoni.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/923-Dachniy-poselok-Ilino.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/954-Kottedjniy-poselok-Petrovskie-Allei-Levitanovo-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/955-Kottedjniy-poselok-Park-Avenyu.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/975-Dachniy-poselok-Opushkino-2.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/987-Kottedjniy-poselok-Arneevo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/villages/994-Poselok-taunhausov-Kembridg.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/457-ABVGDacha.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/494-Megapolis-Megapolis-Property-Management-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/318-Asterra.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/43-Atlas.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/447-Zemelnie-Aktivi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/496-KMD-Development.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/419-Dialog-Development.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/659-Ekolend.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/194-FSK-Lider.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/80-Konti.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/525-Slavenka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/65-Gud-Vud-Good-Wood-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/646-Gud-Zem-Good-Zem-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/498-Honka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/81-Ideal.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/364-Integra.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/637-AyRezidens-iResidence-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/384-Vektor-Investments-Istrinskaya-Dolina-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/564-KaskadStroyServis.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/665-M9-Development.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/499-MegaZem.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/579-Meta-Gruppa-Magazin-Zemli-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/495-Nyurigalend-NewRigaLand-.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/321-SpektrServis.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/479-Rodnie-Zemli.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/203-Sapsan.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/562-Sova-Holding.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/7-StroyArsenal.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/526-Svoya-Dacha.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/338-Svoya-Zemlya.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/529-Turgenevskie-Dachi.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/602-Selsovet.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/4-Villadgio-Esteyt.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/576-Novoe-Stroitelstvo.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/696-Zeleniy-Kvadrat.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/446-ZemAktiv.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/600-Zemleteka.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/527-Zemlya-MO.htm",
    component: component_45stubmU7eoGQtuO
  },
  {
    name: component_45stubmU7eoGQtuOMeta?.name,
    path: "/companies/193-Giltorg.htm",
    component: component_45stubmU7eoGQtuO
  }
]