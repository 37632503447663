export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},{"charset":"utf-8"},{"name":"apple-mobile-web-app-title","content":"TotDom"},{"name":"application-name","content":"TotDom"},{"name":"msapplication-TileColor","content":"#45525A"},{"name":"msapplication-config","content":"/images/favicon/browserconfig.xml"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/images/favicon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/images/favicon/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/images/favicon/favicon-32x32.png"},{"rel":"manifest","href":"/images/favicon/site.webmanifest"},{"rel":"mask-icon","href":"/images/favicon/safari-pinned-tab.svg","color":"#45525A"},{"rel":"shortcut icon","href":"/images/favicon/favicon.ico"}],"style":[],"script":[{"src":"https://mc.yandex.ru/metrika/tag.js","async":true,"tagPosition":"head"}],"noscript":[],"htmlAttrs":{"lang":"ru"},"title":"Totdom","charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false